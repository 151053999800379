import { CommonModule } from '@angular/common';
import {
    NgModule
  } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FooterComponent } from '../components/commonComponents/footer/footer.component';
  import { HeaderComponent } from '../components/commonComponents/header/header.component';
import { LayoutService } from '../services/layout.service';
  @NgModule({
    declarations: [
      HeaderComponent,
      FooterComponent
    ],
    imports:[
        CommonModule,
        MatMenuModule,
        MatIconModule
    ],
    exports:[
        HeaderComponent,
        FooterComponent
    ],
    providers:[LayoutService]
  })
  export class SharedModule {}
  