import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  copyRightYear = '';
  subscriptions: Subscription[] = [];
  constructor(private layoutService:LayoutService) { 
    this.subscriptions.push(
      this.layoutService
        .getCopyrightYear()
        .subscribe((copyRightYear) => {
          this.copyRightYear = copyRightYear;
        })
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
